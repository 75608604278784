import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Box, Typography, Avatar, Card } from '@mui/material';
import icUsers from '../../../assets/images/icons/icUsers.png'
import { useDispatch, useSelector } from 'react-redux';

import Skeleton from '@mui/material/Skeleton';
import { Link, useNavigate } from 'react-router-dom';
import { fetchGeneralPatients } from '../../../redux/patients_general';
import instance from '../../../auth/utils/useJwt';

function RecentPatients() {
    // const { data, loading } = useSelector(state => state.general_patients);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const fetch_patient_activities_data = () => {
        instance.get('/user-activities').then((response) => {
            setData(response.data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (data.length === 0) {
            fetch_patient_activities_data();
        }
    }, [])

    return (
        <Card variant="outlined" sx={{ height: '100%' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
                {/* <Typography variant="h6">Recent Patients</Typography> */}
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <img src={icUsers} alt="icUsers" />
                    <Typography sx={{ fontWeight: 'bold' }} variant="h6">My Recent Cases</Typography>
                </Box>
                <Button
                    onClick={() => navigate('/cases')}
                    sx={{ backgroundColor: "#E9F6FF", fontSize: '13px', textTransform: 'capitalize', px: 2 }} variant="text" color="primary" size="small">
                    All Cases</Button>


            </Box>

            <Table size="small">
                <TableHead sx={{ backgroundColor: "#E9F6FF" }}>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Patient Name</TableCell>
                        <TableCell>Next Action</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                {/* <Skeleton variant="rectangular" width={210} height={118} /> */}
                {loading ?
                    <TableBody>
                        {[...Array(5)].map((patient, index) => (
                            <TableRow key={index} sx={index === 4 ? { '&:last-child td': { borderBottom: 0 } } : {}}>
                                <TableCell><Skeleton variant="text" width={100} /></TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center">
                                        <Skeleton variant="circular" width={30} height={30} />
                                        <Box sx={{ ml: 1 }}>
                                            <Skeleton variant="text" width={100} />
                                        </Box>
                                    </Box>
                                </TableCell>

                                <TableCell sx={{ height: '40px' }}>
                                    <Skeleton variant="text" width={100} />
                                </TableCell>
                            </TableRow>

                        ))}
                    </TableBody>

                    :
                    <TableBody>
                        {data?.slice(0, 5).map((patient, index) => (
                            <TableRow key={patient.id} sx={index === 4 ? { '&:last-child td': { borderBottom: 0 } } : {}}>
                                <TableCell>{patient.romac_case_id}</TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center">
                                        <Avatar alt={`${patient.first_name} ${patient.last_name}`} src="/path_to_image.jpg" sx={{ width: 30, height: 30 }} />
                                        <Typography variant="body2" sx={{ ml: 1 }}>{`${patient.first_name} ${patient.last_name}`}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ height: '40px', fontSize: '13px' }}>
                                    {patient.action?.slice(0, 50)}
                                </TableCell>
                                <TableCell sx={{ height: '40px' }}>
                                    <Button
                                        component={Link}
                                        to={`/patients/${patient.romac_id}/case/${patient.romac_case_id?.split('-')[2]}`}
                                        variant="text" color="primary" size="small" sx={{ textTransform: 'lowercase' }}>View</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                }
            </Table>
        </Card>


    )
}

export default RecentPatients