import React, { useEffect, useState } from "react";
import instance from "../../../../../auth/utils/useJwt";
import SelectedPermissions from "./SelectedPermissions";
import PermissionsSelector from "./PermissionSelector";
import { Box, Button, Divider, MenuItem, Radio, Select, Stack, Table, TableCell, TableRow, Typography } from "@mui/material";
import useSnackbar from "../../../../../components/Dialogs/SnackBar";
import AdminAction from "../../../../../components/PermissionsWrappers/AdminAction";
export default function AdminUserPermissions({
    user_id
}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const fetchPermissions = () => {
        setLoading(true);
        instance.get(`/admin/user/user-privileges?user_id=${user_id}`).then(res => {
            console.log(res.data)
            setSelectedPermissions(res.data);
            setLoading(false);
        }).catch(err => {
            setError("Failed to fetch permissions");
            setLoading(false);
        });
    };

    const [permissions, setPermissions] = useState([]);

    const fetchPermissionsBase = () => {
        instance.get("/admin/user/privileges").then(res => {
            const basic = res.data.filter(p => p.category === "Basic")[0].privileges;
            setPermissions(basic || []);
        }).catch(err => {
            setError("Failed to fetch permissions");
      
        });
    }

    const updatePermissions = (permissions) => {
        setLoading(true);
        instance.post("/admin/user/user-privileges", {
            permissions: permissions,
            user_id: user_id
        }).then(res => {
            showSnackbar("Permissions updated successfully", "success");
            setLoading(false);
            fetchPermissions();
        }).catch(err => {
            showSnackbar("Failed to update permissions", "error");
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchPermissions();
        fetchPermissionsBase();
    }, []);


    const setBasePermissions = (permission_id) => {
       
        const permissionAv = permissions.filter(p => p.id === Number(permission_id));
       
        if (permissionAv?.length > 0) {
            const permission = permissionAv[0];
            // If there is already any permission with category Basic, remove it
            const permissionsCopy = selectedPermissions.filter(p => p.category !== "Basic")
          
            // setSelectedPermissions(selectedPermissions.filter(p => p.category !== "Basic"));


            permissionsCopy.push({
                "privilege_id": permission.id,
                "name": permission.name,
                "category": permission.category,
                "description": permission.description
            });

            setSelectedPermissions(permissionsCopy);
        }
    }


    return (
        <div>
            <SnackbarComponent />
            <Box>
                <Typography variant="h6">Base Permissions</Typography>
                <Typography variant="body2" sx={{
                    color: "#888", mt: 1, mb: 3
                }}>
                    Basic permissions for full app access and admin, manager and viewer
                </Typography>
            </Box>
            {/* {loading && <p>Loading...</p>} */}
            {/* {error && <p>{error}</p>} */}

            {/* <Select size="small" name="basic_permissions" fullWidth onChange={(e) => setBasePermissions(e.target.value)}>
                {
                    permissions.map(({ id, name }) => (
                        <MenuItem key={`region-${id}`} value={id}>{name}</MenuItem>
                    ))
                }
            </Select> */}
            <Table size="small" sx={{
                border: '1px solid #ddd'
            }}>
                {
                    permissions.map(({ id, name, description }) => (
                        <TableRow key={`permission-${id}`}>
                            <TableCell>
                            <Radio key={`permission-${id}`} value={id} onChange={(e) => setBasePermissions(e.target.value)}
                            checked={selectedPermissions.filter(p => p.privilege_id === id).length > 0}
                            />
                            </TableCell>
                            <TableCell>{name}</TableCell>

                            <TableCell sx={{ fontSize: '11px' }}>{description}</TableCell>

                        </TableRow>
                    ))
                }
            </Table>

            <Divider sx={{ my: 3 }} />

            <Box>
                <Typography variant="h6">Module Permissions</Typography>
                <Typography variant="body2" sx={{
                    color: "#888", mt: 1, mb: 3
                }}>
                    Permissions for specific modules
                </Typography>
            </Box>

            <SelectedPermissions selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions} />

            <AdminAction>
                <PermissionsSelector selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions} />


                <Stack direction={"row"} sx={{ my: 5 }}>
                    <Button variant="contained" size="small"
                        onClick={() => updatePermissions(selectedPermissions)}
                        disabled={loading}
                    >Save</Button>
                </Stack>
            </AdminAction>
        </div>
    );
}
