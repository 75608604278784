import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'
import {
  ADMIN_PAGES_ROLES, CASE_ROLES, REPORTS_ROLES, EXPENSES_ROLES, PATIENT_ROLES, MANAGER_ROLE, REFERRAL_ROLES, EXPENSE_AUTHORIZER_ROLE
} from '../configs/roles_list'

export const UserRoles = createSlice({
  name: 'user_roles',
  initialState: {
    roles: [],
    role_names: [],
    regions: [],
    region_names: [],
  },
  reducers: {
    setUserRoles: (state, action) => {
      console.log(action.payload)
      state.roles = action.payload
      state.isAdminAllowed = action.payload.map(role => role.name).some(role => ADMIN_PAGES_ROLES.includes(role))
      state.role_names = action.payload.map(role => role.name)
      state.regions = action.payload.map(role => role.region)
      // remove any region null or undefined
      state.regions = state.regions.filter(region => region !== null && region !== undefined)
      //region_name
      state.region_names = action.payload.map(role => role.region_name)
      // remove any region_name null or undefined
      state.region_names = state.region_names.filter(region_name => region_name !== null && region_name)
    },
    setUserData: (state, action) => {
      state[action.payload.key] = action.payload.value
    }
  }
}) 


//state.role.permissions is an array of string ['VIEW', 'EDIT']
const MANAGER_PATIENT_ROLES = ["Admin", "Manager", "Viewer"]
// If have any of the roles in array that contains from ADMIN_PAGES_ROLES then return true
const CanAccessAdmin = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => ADMIN_PAGES_ROLES.includes(role))
);
// If have any of the roles in array that contains from ADMIN_PAGES_ROLES, but not viewer then return true
const CanEditAdmin = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => ADMIN_PAGES_ROLES.includes(role) && role !== "Viewer")
);
const IsSuperAdmin = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => role === "Admin")
);

const CanModifyCase = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => CASE_ROLES.includes(role) && !['Viewer', 'Patient Viewer', 'Case Viewer'].includes(role))
)

const CanViewCase = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => CASE_ROLES.includes(role))
)

const IsCaseAdmin = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => CASE_ROLES.includes(role) && ['Admin', 'Case Admin'].includes(role))
)

const CanViewReports = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => REPORTS_ROLES.includes(role))
)

const CanViewExpenses = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => EXPENSES_ROLES.includes(role))
)

const CanViewPatient = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => MANAGER_PATIENT_ROLES.includes(role))
)

const CanModifyPatient = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => MANAGER_PATIENT_ROLES.includes(role) && !['Viewer', 'Patient Viewer'].includes(role))
)

const IsPatientAdmin = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => PATIENT_ROLES.includes(role) && ['Admin', 'Patient Admin'].includes(role))
)


const CanModifyReferral = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => ['Admin', 'Referral Manager'].includes(role))
)

const CanAuthorizeExpenses = createSelector(
  [state => state.user_roles.roles],
  (roles) => roles.map(role => role.name).some(role => EXPENSE_AUTHORIZER_ROLE.includes(role))
)

// // Function to check if the user has write permissions
// export const hasWritePermission = createSelector(
//     [selectPermissions],
//     (permissions) => permissions?.includes('EDIT')
// );

// export const hasDeletePermission = createSelector(
//     [selectRole],
//     (role) => role.type === "A"
// );

// export const hasXeroAccess = createSelector(
//     [xeroAccess],
//     (xero_access) => xero_access
// );

export { CanAccessAdmin, CanEditAdmin, IsSuperAdmin, 
  CanModifyCase, CanViewCase, IsCaseAdmin,
  CanViewReports, CanViewExpenses, 
  CanViewPatient, CanModifyPatient, IsPatientAdmin,
  CanModifyReferral, CanAuthorizeExpenses

}
export const { setUserRoles, setUserData} = UserRoles.actions

export default UserRoles.reducer