import {
    Box,
    Checkbox,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRegions } from "../../../../../redux/region";
import instance from "../../../../../auth/utils/useJwt";
import useSnackbar from "../../../../../components/Dialogs/SnackBar";
export default function Regions({ id }) {
    const dispatch = useDispatch();
    const regions = useSelector((state) => state.regions).data;
    const [userRegions, setUserRegions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [SnackbarComponent, showSnackbar] = useSnackbar();

    const fetchUserRegions = () => {
        setLoading(true);
        instance
            .get(`/admin/user/regions?user_id=${id}`)
            .then((res) => {
                setUserRegions(res.data); // Assuming res.data is an array of region IDs
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (regions.length === 0) {
            dispatch(fetchRegions());
        }
        fetchUserRegions();
    }, []);

    const handleCheckboxChange = (event, regionId) => {
        if (event.target.checked) {
            setUserRegions([...userRegions, regionId]);
        } else {
            setUserRegions(userRegions.filter((id) => id !== regionId));
        }
    };

    const toggleRegion = (regionId) => {
        if (userRegions.includes(regionId)) {
            setUserRegions(userRegions.filter((id) => id !== regionId));
        } else {
            setUserRegions([...userRegions, regionId]);
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        instance
            .post(`/admin/user/regions`, {
                user_id: id,
                regions: userRegions,
            })
            .then((res) => {
                setLoading(false);
                showSnackbar("Regions updated successfully", "success");
                // Optionally handle success
            })
            .catch((err) => {
                setLoading(false);
                showSnackbar("Failed to update regions", "error");
                // Optionally handle error
            });
    };

    return (
        <Box>
            <SnackbarComponent />
            <TableContainer>
                <Table size="small" sx={{
                    border: '1px solid #ddd', mb: 2
                }}>
                    <TableBody>
                        {regions.map((region) => (
                            <TableRow key={region.id}>
                                <TableCell sx={{ width: "50px" }}>
                                    <Checkbox
                                        size="small"
                                        checked={userRegions.includes(region.id)}
                                        onChange={(e) => handleCheckboxChange(e, region.id)}
                                    />
                                </TableCell>
                                <TableCell
                                    onClick={() => toggleRegion(region.id)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {region.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="contained" onClick={handleSubmit} disabled={loading}>
                {loading ? "Loading..." : "Save"}
            </Button>
        </Box>
    );
}
