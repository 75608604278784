import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux';
import { fetchReferralPatients } from '../../../redux/referral_patients';
import BallotIcon from '@mui/icons-material/Ballot';
import PaidIcon from '@mui/icons-material/Paid';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import {
    EXPENSES_ROLES, ALL_ROLES, REFERRAL_ROLES, DONATION_ROLES, CASE_ROLES, PATIENT_ROLES, ADMIN_PAGES_ROLES, REPORTS_ROLES,
    MANAGER_ROLE
} from '../../../configs/roles_list';


export default function SidebarMenu() {

    const { allData } = useSelector(state => state.referral_patients)
    const dispatch = useDispatch()
    const [open, setOpen] = useState(true)
    const location = useLocation()
    const [expanded, setExpanded] = useState(null)
    const {role_names} = useSelector(state => state.user_roles)

    const sidebar_menu = [
        { name: 'Dashboard', icon: <DashboardIcon />, slug: '/', roles: [...ALL_ROLES, ...MANAGER_ROLE] },
        { name: 'Referrals', icon: <DeviceHubIcon />, slug: '/referrals', value: allData?.length, roles: REFERRAL_ROLES},
        { name: 'Patients', icon: <PeopleOutlineIcon />, slug: '/patients', roles: [...PATIENT_ROLES, ...MANAGER_ROLE] },
        { name: 'Cases', icon: <BallotIcon />, slug: '/cases', roles: [...CASE_ROLES, ...MANAGER_ROLE] },
        
        { name: 'Expenses', icon: <PaidIcon />, slug: '/pam/expenses', roles: EXPENSES_ROLES },
        
        { name: 'Donations', icon: <VolunteerActivismIcon />, slug: '/donations', roles: DONATION_ROLES },
        // { name: 'Training', icon: <LightbulbIcon />, slug: '/training', roles: [...ALL_ROLES, ...MANAGER_ROLE] },
        {
            name: 'Reports', icon: <ArticleIcon />, slug: '/reports', roles: REPORTS_ROLES,
            sub_menu: [
                { name: 'Overview', slug: '/reports/overview' },
                { name: 'Patient Cases', slug: '/reports/patient-cases' },
                { name: 'Expenditure', slug: '/reports/expenditures' },
                { name: "Insurance", slug: '/reports/insurance' },
                { name: 'Annual', slug: '/reports/annual' },
                { name: 'Map', slug: '/reports/map' },
            ]
        },
        {
            name: 'Admin Panel', icon: <SettingsIcon />, slug: '/admin', roles: ADMIN_PAGES_ROLES,
            sub_menu: [
                { name: 'Lists', slug: '/admin/lists' },
                { name: 'Users', slug: '/admin/users' },
                // { name: 'Roles', slug: '/admin/roles' },
                // { name: 'Trainings', slug: '/admin/trainings' },
                { name: 'Org Settings', slug: '/admin/settings' },
            ]
        }
    ]

    useEffect(() => {
        const sp = location.pathname?.split("/")[1]
        if (['admin', 'reports'].includes(sp)) {
            setExpanded(`/${sp}`)
        }
    }, [location.pathname])

    const onMenuItemClick = (item) => {
        if (item.sub_menu) {
            if (expanded === item.slug) {
                setExpanded(null)
            } else {
                setExpanded(item.slug)
            }
        }
    }

    useEffect(() => {
        if (allData.length === 0) {
            dispatch(fetchReferralPatients())
        }
    }, [])

    const isCurrentNavLink = (item) => {
        const { pathname } = location
        if (item === "/referrals") {
            if (location.pathname.slice(10, 11) === "P" || item === location.pathname) {
                return true
            }
        }
        else if (item === "/patients") {
            // Only if "/case" is not the current path
            if (!location.pathname.includes("/case") && location.pathname.slice(10, 11) === "R") {
                return item === location.pathname.slice(0, 9)
            }
            return item === location.pathname
        }
        else if (item === "/cases"){
            if ((location.pathname.slice(10, 11) === "R" || item === location.pathname) && location.pathname.includes("/case")) {
                return true
            }
        } // else check if pathname string starts with item
        else if (item === "/reports"){
            if (location.pathname.slice(0, 8) === "/reports" || item === location.pathname) {
                return true
            }
        }else if (item === "/admin"){
            if (location.pathname.slice(0, 6) === "/admin" || item === location.pathname) {
                return true
            }
        }
        
        else{
            return item === location.pathname
        }
    }
    
    return (
        <List>
            {sidebar_menu.map((sb_item, index) => (
                <span key={`item-${index}`}>
                    {role_names.some(role => sb_item.roles?.includes(role)) &&
                    
                        <span>
                            <ListItem key={sb_item.name} disablePadding sx={{ display: 'block', px: 1.3 }}>
                                <ListItemButton
                                    component={sb_item.sub_menu ? Button : Link}
                                    to={sb_item.sub_menu ? '#' : sb_item.slug}
                                    onClick={() => onMenuItemClick(sb_item)}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        py: 1.3,
                                        backgroundColor: isCurrentNavLink(sb_item.slug) ? 'rgba(0, 127, 255, 1)' : 'none',
                                        color: isCurrentNavLink(sb_item.slug) ? '#fff' : 'none',
                                        borderRadius: '7px',
                                        '&:hover': {
                                            backgroundColor: isCurrentNavLink(sb_item.slug) ? 'rgba(0, 127, 255, 0.8)' : 'rgba(0, 127, 255, 0.2)',
                                            color: isCurrentNavLink(sb_item.slug) ? '#fff' : 'none',
                                        }
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: isCurrentNavLink(sb_item.slug) ? '#fff' : 'none',
                                        }}
                                    >
                                        {sb_item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={sb_item.name} sx={{ opacity: open ? 1 : 0, textTransform: 'capitalize' }} />
                                    {
                                        sb_item.value ?
                                        (
                                        sb_item.value !== 0 ?
                                        <Box sx={{ backgroundColor: "#C79121", color: "#fff", fontWeight: 'bold', padding: '2px 9px', borderRadius: '12px' }}>
                                            { sb_item.value }
                                        </Box>
                                        : null
                                        ) : null
                                    }


                                    {
                                        sb_item.sub_menu &&
                                        <ListItemIcon
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                minWidth: 0,
                                                ml: 'auto',
                                                color: isCurrentNavLink(sb_item.slug) ? '#fff' : 'none',
                                            }}
                                        >
                                            {sb_item.slug === expanded ? <RemoveIcon /> :
                                                <AddIcon />}
                                        </ListItemIcon>

                                    }
                                </ListItemButton>

                            </ListItem>


                            {sb_item.sub_menu && sb_item.slug === expanded && (

                                sb_item.sub_menu.map((sub_item) => (
                                    <ListItem
                                        key={sub_item.name}
                                        sx={{ px: 1.3, py: 0 }}
                                    >
                                        <ListItemButton
                                            component={Link}
                                            to={sub_item.slug}
                                            sx={{
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 3.5,
                                                //backgroundColor: isCurrentNavLink(sub_item.slug) ? 'rgba(0, 127, 255, 0.3)' : 'none',
                                                //color: isCurrentNavLink(sub_item.slug) ? '#fff' : 'none',
                                                //sCurrentNavLink(sub_item.slug) ? 'bold' : 'normal',
                                                //fontSize: '30px !important',
                                                borderRadius: '7px',
                                                '&:hover': {
                                                    backgroundColor: isCurrentNavLink(sb_item.slug) ? 'rgba(0, 127, 255, 0.1)' : 'rgba(0, 127, 255, 0.2)',
                                                    //color: isCurrentNavLink(sb_item.slug) ? '#fff' : 'none',
                                                }
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                    //color: sb_item.slug === location.pathname ? '#fff' : 'none',
                                                }}
                                            >
                                                <FiberManualRecordOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText sx={{ fontWeight: isCurrentNavLink(sub_item.slug) ? 'bold' : 'none' }} disableTypography primary={sub_item.name} />
                                        </ListItemButton>
                                    </ListItem>
                                ))

                            )}
                        </span>
                    }
                </span>
            ))}
        </List>
    )
}